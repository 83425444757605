import React from "react";
import DataTable from "components/DataTable";
import { Box } from "components/admin-lte";
import PageLayout from "components/PageLayout";
import { ButtonGroup, Col, ControlLabel, FormGroup, Row, ToggleButtonGroup, ToggleButton} from "react-bootstrap";
import AddDialog from "./AddDialog";
import InvoiceHtml from "./InvoiceHtml";
import { UserContext } from "lib/user";
import { N } from "lib/Functions";
import moment from "moment-timezone";
//import DateRangePicker from "components/pickers/DateRangePicker";
//import DatePicker from "components/pickers/DatePicker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { InvoiceRecipientPicker } from "components/pickers/SelectPickers";
import $ from "jquery";
import axios from "axios";
import jsPDF from "jspdf";
import "./styles.css";

//status: //1: pending, 2: invoice_issued
const Statuses = {
  1:"-",
  2:"Invoice Issued"
};

function renderPeriod(d, t, r, m) {
  /*if (d && (t === "display" || t === "filter" || t === "sort")) {
    return moment.utc(d).format("MM/YYYY");
  }*/
  return d;
}

function renderInvoiceDate(d, t, r, m, selectedDate) {
  if(t === "filter")
  {
    if(d) {
      var format = "YYYY-MM-DD";
      var date = new Date(moment.utc(d).format(format));
      if(selectedDate && selectedDate.length===2){
        var _dateMin = moment.utc(selectedDate[0]).format(format);
        var _dateMax = moment.utc(selectedDate[1]).format(format);
        var dateMin = new Date(_dateMin);
        var dateMax = new Date(_dateMax);
        if (
          ( dateMin === null && dateMax === null ) ||
          ( dateMin === null && date <= dateMax ) ||
          ( dateMin <= date   && dateMax === null ) ||
          ( dateMin <= date   && date <= dateMax )
        ) {
          return _dateMin+"_"+_dateMax;
        }
      }
      return moment.utc(d).format(format);
    }
    else {
      return "";
    }
  }
  
  if (d && t === "display") {
    return moment.utc(d).format("DD/MM/YYYY");
  }
  return d;
}

function renderAccountManagerName(d, t, r, m) {
  if (t === "display" || t === "filter" || t === "sort") {
    if(d!==null)
      return m.settings.json.accountManagerNames[d] || d;
    else 
      return '';
  }
  return d;
}

function renderNotes(d, t, r, m) {
  if (t === "display" || t === "filter" || t === "sort") {
    if (d !== null && d.length>8) {
      //return d.substring(0, 7) + '<b>...</b>';
      return '<div class="show-hide-text">' +
        '<a id ="' + r._id + '" class="show-less" href="#' + r._id + '_less'+'" > Show less</a>' +
        '<a id ="' + r._id + '_less' + '" class="show-more" href="#' + r._id +'">Show more</a>' +
        '<p>' + d + '</p></div >'
    }


  }
  return d;
}



function renderCustomerName(d, t, r, m) {
  if (t === "display" || t === "filter" || t === "sort") {
    if (d !== null)
      if (r.total_prepayments_amount === 0 || !r.total_prepayments_amount) {
        return m.settings.json.customerNames[d] || d;
      }
      else {
        return m.settings.json.customerNames[d] + ' - PREPAID' || d;
      }
      
    else
      return '';
  }
  return d;
}

function renderIssuerName(d, t, r, m) {
  if (t === "display" || t === "filter" || t === "sort") {
    if(d!==null)
      return m.settings.json.issuerNames[d] || d;
    else
      return '';
  }
  return d;
}

function renderStatus(d, t, r, m) {
  if (t === "display") {
    if(d!==null)
      return Statuses[d];
    else
      return '';
  }
  return d;
}

function renderN(d, t, r, m){
  if (t === "display" || t === "filter" || t === "sort") {
    if(d!==null && d!==undefined)
      return N(d, 2);
    else
      return '';
  }
  return d;
}

function renderCreateInvoiceBtn(d, t, r, m){
  if (t === "display" || t === "filter" || t === "sort") {
    
    if(r.invoice_period_to!==null){
      var date = new Date(moment.utc(r.invoice_period_to).format("YYYY-MM-DD"));
      var today = new Date();

      if(r.status===1 && r.invoice_id===null && date<today){
        var disabled = ((r.advertiser_revenue !== null && r.advertiser_deducted_amount !== null && (r.advertiser_revenue - r.advertiser_deducted_amount - r.advertiser_discount_amount) > 0) && (r.total_prepayments_amount === 0) && (r.total_remaining_invoiced_operations_amount === 0))
          ? '' : ' disabled';
        return ( "<div><button type='button' class='btn btn-default btn-small"+disabled+"' "+disabled.substring(1)+"> Create Invoice </button></div>");
      }
    }
  }
  return '';
}

function allowEditAccountingOperations(invoiceDate) {
  return true;
  let invoiceEditDateEnd;
  invoiceEditDateEnd = moment(invoiceDate);
  invoiceEditDateEnd.add(1, 'months').date(10);
  let now = moment();
  if (now > invoiceEditDateEnd) {
    return false;
  }
  else {
    return true;
  }
}

export default class AccountingOperations extends React.Component {
  static title = "Accounting Operations";

  tableRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {     
      showAdd: false,
      showPrintPdf: false,
      busy: false,
      error: null,
      showEditDataFor: null,
      showPdfDataFor: null,
      selectedDate: [null,null], //[moment().startOf("day"),moment().endOf("day")],
      selectedDateFrom: null,//moment().startOf("day"),
      selectedDateTo: null,//moment().endOf("day"),
      defaultInvoicePeriodValue: moment().subtract(1, "month").format('MM/YYYY'),
      invoiceData: null,
      tableUrl: "/api/v1/tools/accountingoperations",
      selectedPeriod: [new Date((new Date()).setMonth(new Date().getMonth() - 1)), new Date((new Date()).setMonth(new Date().getMonth() - 1))],
      selectedStartDate: new Date((new Date()).setMonth(new Date().getMonth() - 1)),
      selectedEndDate: new Date((new Date()).setMonth(new Date().getMonth() - 1)),
      selectedStatus: ""
    };
    this.issuerRef = React.createRef();
    this.recipientRef = React.createRef();
    this.accountManagerRef = React.createRef();
    this.selectIssuer = this.selectIssuer.bind(this);
    this.selectCustomer = this.selectCustomer.bind(this);
    this.selectAccountManager = this.selectAccountManager.bind(this);
    this.handleCreateInvoice = this.handleCreateInvoice.bind(this);
    this.handlePdfClick = this.handlePdfClick.bind(this);
    this.getInvoice = this.getInvoice.bind(this);
    //this.formatInvoicePeriod = this.formatInvoicePeriod.bind(this);
    //this.calculateInvoiceLinesTotal = this.calculateInvoiceLinesTotal.bind(this);
    //this.calculateInvoiceTotal = this.calculateInvoiceTotal.bind(this);
    //this.calculateVat = this.calculateVat.bind(this);
    //this.getInvoiceLinesHtml = this.getInvoiceLinesHtml.bind(this);
  }

  componentDidMount() {
    //this.selectInvoicePeriod(this.state.defaultInvoicePeriodValue);
  }
  
  render() {
    
    const { showEditDataFor, showPdfDataFor, invoiceData } = this.state;
    const selectedPeriod = (this.state && this.state.selectedPeriod) ? this.state.selectedPeriod : null;
    const selectedStartDate = (this.state && this.state.selectedStartDate) ? this.state.selectedStartDate : null;
    const selectedEndDate = (this.state && this.state.selectedEndDate) ? this.state.selectedEndDate : null;
    const selectedStatus = (this.state && this.state.selectedStatus) ? this.state.selectedStatus : "";

    return (
      <>
        <PageLayout
          breadcrumbs={["Tools"]}
          title={AccountingOperations.title}
          description={
          <p>
          </p>
          }
        >
          <UserContext.Consumer>
          {user => (
            <Box>
                <Box.Header>

                  <DataTable.Toolbar tableRef={this.tableRef} bsSize="small">
                    <ButtonGroup>
                      <DatePicker
                        className="form-control date-picker-filter-input"
                        wrapperClassName="datePicker"
                        selected={selectedStartDate}
                        startDate={selectedStartDate}
                        endDate={selectedEndDate}
                        onChange={(values) => {
                          const newStartDate = values && values[0] ? values[0] : null;
                          const newEndDate = values && values[1] ? values[1] : null;
                          this.selectDate(newStartDate, newEndDate);
                          }
                        }
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        isClearable
                        selectsRange
                      />
                    </ButtonGroup>
                    <ButtonGroup>
                    <select
                      className="form-control select-filter-input"
                      value={selectedStatus}
                      disabled={false}
                      onChange={e => {
                          this.setState({ selectedStatus: e.target.value });
                          this.handleReload("selectedStatus", e.target.value);
                        }
                      }
                    >
                      <option key="key_selectType0" value="">Status</option>
                      <option key="key_selectType1" value="1">-</option>
                      <option key="key_selectType2" value="2">Invoice issued</option>
                      </select>
                    </ButtonGroup>

                    <ToggleButtonGroup
                      bsSize="small"
                      label="Issuer"
                      type="checkbox"
                      value={this.state.issuerName}
                      onChange={(e) => {
                        var value = e;
                        if (value.length === 0) {
                          this.setState({ issuerName: e });
                          //this.selectIssuerToggle(null);
                          this.handleReload("issuer_id", value);
                        }
                        else {
                          var prevState = this.state.issuerName;
                          if (prevState && prevState.length > 0) {
                            value = e.filter(function (val) { return val !== prevState[0] })
                          }                          
                          this.setState({ issuerName: value });
                          //this.selectIssuerToggle(value);
                          this.handleReload("issuer_id", value);
                        }
                      }}
                    >
                      <ToggleButton value={"6352910839f28166b1b99e40"}>APPMULTIPLE</ToggleButton>
                      <ToggleButton value={"6352911439f28166b1b99e41"}>NYDRI</ToggleButton>
                      <ToggleButton value={"6352911f39f28166b1b99e42"}>ADSTAMP</ToggleButton>
                      <ToggleButton value={"6352912639f28166b1b99e43"}>MINIMOB PTE</ToggleButton>
                      <ToggleButton value={"6352958b39f28166b1b99ea4"}>KAN</ToggleButton>
                      <ToggleButton value={"6352959139f28166b1b99ea5"}>SPINX</ToggleButton>
                      <ToggleButton value={"635a5b2f55fc474b24607509"}>ADSPIN</ToggleButton>
             
                    </ToggleButtonGroup>
                    <br /><br />

                    <ToggleButtonGroup
                      bsSize="small"
                      label="Account Manager"
                      type="checkbox"
                      value={this.state.amName}
                      onChange={(e) => {
                        var value = e;
                        if (value.length === 0) {
                          this.setState({ amName: e });
                          //this.selectAccountManagerToggle(null);
                          this.handleReload("salesman_id", value);
                        }
                        else {
                          var prevState = this.state.amName;
                          if (prevState && prevState.length > 0) {
                            value = e.filter(function (val) { return val !== prevState[0] })
                          }
                          this.setState({ amName: value });
                          //this.selectAccountManagerToggle(value);
                          this.handleReload("salesman_id", value);

                        }
                      }}
                    >
                      <ToggleButton value={"40"}>f.mpilis</ToggleButton>
                      <ToggleButton value={"24"}>a.gkoutzina</ToggleButton>
                      <ToggleButton value={"5"}>m.dimitropoulou</ToggleButton>
                      <ToggleButton value={"32"}>d.velonis</ToggleButton>
                      <ToggleButton value={"25"}>j.chua</ToggleButton>
                      <ToggleButton value={"4"}>a.dimitropoulos</ToggleButton>
                      <ToggleButton value={"61"}>m.namachivayam</ToggleButton>
                    </ToggleButtonGroup>
                    
                </DataTable.Toolbar>
                <br/>
                <Row>
                  <Col xs={12} sm={4} lg={2}>
                    <FormGroup>
                      <ControlLabel style={{width: "100%"}}>
                        Customer
                      </ControlLabel>
                      <InvoiceRecipientPicker
                          ref={this.recipientRef}
                          className="form-control"
                          placeholder="Enter name"
                          onChange={(customerId, customerName) => {
                            this.setState({ customerId: customerId });
                            this.handleReload("customer_id", customerId)
                          }
                          }
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Box.Header>
              <Box.Body>
                <DataTable
                  className="table table-condensed table-striped"
                  ref={this.tableRef}
                  ajax={{
                    url: this.getTableUrl('init'),
                    dataSrc: "items"
                  }}
                  onUserCommand={({ commandData }) => {
                    if (user.tokenHasExpired) {
                      window.location.replace('/login');
                      return;
                    } else {
                      if (commandData) {
                          if (commandData.action === 'edit') {
                            var json = this.tableRef.current.getAjaxJson();
                            if(json){
                              commandData.issuerName = json?.issuerNames[commandData.issuerId]||"";
                              commandData.customerName = json?.customerNames[commandData.customerId]||"";
                            }
                            this.setState({showEditDataFor: commandData});
                          }
                          if (commandData.action === 'create_invoice') {
                            this.handleCreateInvoice(commandData.id);
                          }

                          if (commandData.action === 'exportInvoiceToPdf') {
                            this.setState({ showPdfDataFor: commandData });
                            this.handlePdfClick(commandData.id, commandData.invoiceId);
                          }
                          
                          if (commandData.action === 'open_invoices') {
                            window.location.replace('/tools/invoices');
                          }
                        }
                      }
                    }
                  }
                  columns={[
                    {
                      title: "Period",
                      data: "invoice_period",
                      name: "invoice_period",
                      render: renderPeriod
                    },
                    {
                      title: "Issuer",
                      data: "issuer_id",
                      name: "issuer_id",
                      width: "10%",
                      /*className: "truncate-cell-wide",*/
                      render: renderIssuerName
                    },
                    {
                      title: "Customer",
                      data: "customer_id",
                      name: "customer_id",
                      width: "10%",
                      render: renderCustomerName
                    },
                    /*
                    {
                      title: "Accounting Balance",
                      data: "balance",
                      name: "balance",
                      className: "text-right",
                      render: renderN
                    },
                    */
                    {
                      title: "Total Uninvoiced Revenues",
                      data: "total_uninvoiced_operations_amount",
                      name: "total_uninvoiced_operations_amount",
                      className: "text-right",
                      render: renderN
                    },
                    {
                      title: "Remaining Balance from Prepayments",
                      data: "total_remaining_invoiced_operations_amount",
                      name: "total_remaining_invoiced_operations_amount",
                      className: "text-right",
                      render: renderN
                    },
                    {
                      title: "Account Manager",
                      data: "salesman_id",
                      name: "salesman_id",
                      width: "10%",
                      render: renderAccountManagerName
                    },
                    {
                      title: "Notes",
                      data: "notes",
                      render: renderNotes
                    },
                    {
                      title: "Platform Revenue",
                      data: "platform_revenue",
                      className: "text-right",
                      render: renderN
                    },
                    {
                      title: "Advertiser Revenue",
                      data: "advertiser_revenue",
                      className: "text-right",
                      render: renderN
                    },
                    {
                      title: "Advertiser Deducted Amount",
                      data: "advertiser_deducted_amount",
                      className: "text-right",
                      render: renderN
                    },
                    {
                      title: "Advertiser Confirmed Amount",
                      data: "advertiser_confirmed_amount",
                      className: "text-right",
                      render: renderN
                    },
                    {
                      title: "Advertiser Discount Amount",
                      data: "advertiser_discount_amount",
                      className: "text-right",
                      render: renderN
                    },
                    {
                      title: "Platform Mediacost",
                      data: "platform_mediacost",
                      className: "text-right",
                      render: renderN
                    },
                    {
                      title: "Status",
                      data: "status",
                      name: "status",
                      className: "text-center",
                      width: "8%",
                      render: renderStatus
                    },
                    {
                      title: "Invoice No",
                      data: "invoice_no",
                      className: "text-center",
                      width: "60px",
                    },
                    DataTable.createUserCommandColumn({
                      title: "",
                      data: "invoice_no",
                      className: "text-right no-padding",
                      width: "30px",
                      orderable: false,
                      commandName: "",
                      commandData: ({ rowData }) => ({
                        action: 'exportInvoiceToPdf',
                        id: rowData._id,
                        invoiceId: rowData.invoice_id,
                        confirmedAmount: rowData.advertiser_confirmed_amount,
                        deductedAmount: 0, //Todo: new field 
                        currency:"USD",
                        currencySymbol:"$"
                      }),
                      render: (d, t, r, m) => (r.invoice_no!==null?("<div><button type='button' class='btn btn-default btn-small' style='width: 30px'> $ </button></div>"):"")
                    }),
                    DataTable.createUserCommandColumn({
                      title: "Invoice",
                      data: "invoice_no",
                      className: "text-center no-padding",
                      width: "30px",
                      orderable: false,
                      commandName: "",
                      commandData: ({ rowData }) => ({
                        action: 'exportInvoiceToPdf',
                        id: rowData._id,
                        invoiceId: rowData.invoice_id,
                        confirmedAmount: rowData.advertiser_confirmed_amount,
                        deductedAmount: 0, //Todo: new field 
                        currency: rowData.currency !== "USD" ? rowData.currency : "EUR",
                        currencySymbol: rowData.currency_symbol !== "$" ? rowData.currency_symbol : "€"
                      }),
                      render: (d, t, r, m) => (r.invoice_no !== null ? ("<div><button type='button' class='btn btn-default btn-small' style='width: 30px' " + ((r.exchange_rate > 0) ? "" : "disabled") + "> " + (r.currency_symbol !== '$' ? r.currency_symbol : '€') +" </button></div>") : "")
                    }),
                    DataTable.createUserCommandColumn({
                      title: "",
                      data: "invoice_no",
                      className: "no-padding",
                      width: "30px",
                      orderable: false,
                      commandName: "",
                      commandData: ({ rowData }) => ({
                        action: 'exportInvoiceToPdf',
                        id: rowData._id,
                        invoiceId: rowData.invoice_id,
                        confirmedAmount: rowData.advertiser_confirmed_amount,
                        deductedAmount: 0, //Todo: new field 
                        currency: "USD",
                        currency2: rowData.currency !== "USD" ? rowData.currency : "EUR",
                        currencySymbol: "$",
                        currencySymbol2: rowData.currency_symbol !== "$" ? rowData.currency_symbol : "€",
                      }),
                      render: (d, t, r, m) => (r.invoice_no !== null ? ("<div><button type='button' class='btn btn-default btn-small' style='width: 36px' " + ((r.exchange_rate > 0) ? "" : "disabled") + "> $ " + (r.currency_symbol !== '$' ? r.currency_symbol : '€') +" </button></div>"):"")
                    }),
                    {
                      title: "Invoice Date",
                      data: "invoice_date",
                      name: "invoice_date",
                      width: "80px",
                      render: (d, t, r, m) => renderInvoiceDate(d, t, r, m, this.state.selectedDate),
                    },
                    DataTable.createUserCommandColumn({
                      title: "",
                      data: null,
                      className: "text-right",
                      orderable: false,
                      defultContent: "",
                      width: "30px",
                      commandName: "",
                      commandData: ({ rowData }) => ({
                        action: 'edit',
                        id: rowData._id,
                        status: rowData.status,
                        issuerId: rowData.issuer_id,
                        customerId: rowData.customer_id,
                        advertiserRevenue: rowData.advertiser_revenue,
                        advertiserConfirmedAmount: rowData.advertiser_confirmed_amount,
                        advertiserDiscountAmount: rowData.advertiser_discount_amount,
                        platformMediacost: rowData.platform_mediacost,
                        platformRevenue: rowData.platform_revenue,
                        notes: rowData.notes,
                        invoice_id: rowData.invoice_id,
                        invoice_date: rowData.invoice_date
                      }),
                      render: (d, t, r, m) => ("<div><button type='button' class='btn btn-default btn-small'" + (!allowEditAccountingOperations(r.invoice_date) ? ' disabled' : '') + "> Edit </button></div>")
                    }),
                    DataTable.createUserCommandColumn({
                      title: "",
                      data: null,
                      className: "text-right",
                      orderable: false,
                      defultContent: "",
                      width: "80px",
                      commandName: "",
                      commandData: ({ rowData }) => ({
                        action: 'create_invoice',
                        id: rowData._id,
                      }),
                      render: renderCreateInvoiceBtn
                    })
                  ]}
                  order={[[1, "asc"]]}
                  footerCallback={(tfoot, data, start, end, display) => {
                    const gt = {
                      platformRevenue: display.reduce((acc, cur) => (acc += data[cur].platform_revenue), 0),
                      advertiserRevenue: display.reduce((acc, cur) => (acc += data[cur].advertiser_revenue), 0),
                      advertiserDeductedAmount: display.reduce((acc, cur) => (acc += data[cur].advertiser_deducted_amount), 0),
                      advertiserConfirmedAmount: display.reduce((acc, cur) => (acc += data[cur].advertiser_confirmed_amount), 0),
                      advertiserDiscountAmount: display.reduce((acc, cur) => (acc += data[cur].advertiser_discount_amount), 0),
                      platformMediacost: display.reduce((acc, cur) => (acc += data[cur].platform_mediacost), 0)
                    };
                   
                    const renderMoney = d => DataTable.StockRenderers.money()(d, "display");
                    const footer = $(tfoot).parent();

                    // Table totals
                    footer.find("#gtplatformRevenue").html(renderMoney(gt.platformRevenue));
                    footer.find("#gtadvertiserRevenue").html(renderMoney(gt.advertiserRevenue));
                    footer.find("#gtadvertiserDeductedAmount").html(renderMoney(gt.advertiserDeductedAmount));
                    footer.find("#gtadvertiserConfirmedAmount").html(renderMoney(gt.advertiserConfirmedAmount));
                    footer.find("#gtadvertiserDiscountAmount").html(renderMoney(gt.advertiserDiscountAmount));
                    footer.find("#gtplatformMediacost").html(renderMoney(gt.platformMediacost));
                  }}
                >
                 <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody></tbody>
                  <tfoot>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="text-right">
                      Totals
                    </td>
                    <td className="text-right" id="gtplatformRevenue">0.00</td>
                    <td className="text-right" id="gtadvertiserRevenue">0.00</td>
                    <td className="text-right" id="gtadvertiserDeductedAmount">0.00</td>
                    <td className="text-right" id="gtadvertiserConfirmedAmount">0.00</td>
                    <td className="text-right" id="gtadvertiserDiscountAmount">0.00</td>
                    <td className="text-right" id="gtplatformMediacost">0.00</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  </tfoot>
                </DataTable>
              </Box.Body>
            </Box>
          )}
          </UserContext.Consumer>

          {/* Invoice html */}            
          {invoiceData && (
            <InvoiceHtml
              invoiceData={invoiceData || null}
              showPdfDataFor={showPdfDataFor || null}
            />
          )}          
        </PageLayout>

        {showEditDataFor && (
          <AddDialog
            action={'edit'}
            id={showEditDataFor.id}
            status={showEditDataFor.status}
            issuerName={showEditDataFor.issuerName}
            customerName={showEditDataFor.customerName}
            advertiserRevenue={showEditDataFor.advertiserRevenue}
            advertiserConfirmedAmount={showEditDataFor.advertiserConfirmedAmount}
            advertiserDiscountAmount={showEditDataFor.advertiserDiscountAmount}
            platformMediacost={showEditDataFor.platformMediacost}
            platformRevenue={showEditDataFor.platformRevenue}
            notes={showEditDataFor.notes}
            invoice_id={showEditDataFor.invoice_id}
            invoice_date={showEditDataFor.invoice_date}
            //Statuses={Statuses}
            onHide={() => {
              this.tableRef.current.reload(() =>
                this.setState({
                  busy: false,
                  error: null,
                  showEdit: false, 
                  showEditDataFor: null
                })
              );
            }}
          />
        )}
        
        {/*{this.state.showPrintPdf && (
          <PrintPdfDialog
            onHide={() => {
              this.tableRef.current.reload(() =>
                this.setState({
                  busy: false,
                  error: null,
                  showPrintPdf: false
                })
              );
            }}
          />
        )}*/}
        
        
      </>
    );
  }
  
  handlePdfClick(id,invoiceId){
    this.getInvoice(invoiceId);  
  }
  
  selectInvoicePeriod(date) {
    var columnName = "invoice_period";
    if(date){
      this.tableRef.current.setColumnFilter(`${columnName}:name`, date);
    }
    else{
      this.tableRef.current.setColumnFilter(`${columnName}:name`, "");
    }
    this.tableRef.current.api.cells(null, `${columnName}:name`).invalidate().draw();
  }
  
  selectDate(newStartDate, newEndDate) {
    this.setState({ selectedStartDate: newStartDate }, () => {
      this.setState({ selectedEndDate: newEndDate }, () => {
        this.handleReload("selectedPeriod", [newStartDate, newEndDate]);
        this.setState({ selectedDate: newStartDate }, () => {
            var columnName = "invoice_date";
            this.tableRef.current.setColumnFilter(`${columnName}:name`, "");
            this.tableRef.current.api.cells(null, `${columnName}:name`).invalidate().draw();
          });
        });
    });
  }
  
  getSelectedDate(){
    var selectedDate = this.state.selectedDate;
    if(selectedDate && selectedDate.length===2){
      var dateFrom = moment.utc(selectedDate[0]).format("YYYY-MM-DD");
      var dateTo = moment.utc(selectedDate[1]).format("YYYY-MM-DD");
      return [dateFrom,dateTo];
    }
    else {
      return [selectedDate,selectedDate];
    }
  }

  selectIssuer(issuerId, issuerName) {
    this.setState({ issuerId: issuerId, issuerName: issuerName }, () => {
      var columnName = "issuer_id";
      if(issuerId && issuerName){
        this.tableRef.current.setColumnFilter(`${columnName}:name`, issuerName);
      }
      else{
        this.tableRef.current.setColumnFilter(`${columnName}:name`, "");
      }
      this.tableRef.current.api.cells(null, `${columnName}:name`).invalidate().draw();
    });
  }

  selectIssuerToggle(issuerName) {
      var columnName = "issuer_id";
      if (issuerName) {
        this.tableRef.current.setColumnFilter(`${columnName}:name`, issuerName);
      }
      else {
        this.tableRef.current.setColumnFilter(`${columnName}:name`, "");
      }
      this.tableRef.current.api.cells(null, `${columnName}:name`).invalidate().draw();
  }

  selectCustomer(customerId, customerName) {
    this.setState({ customerId: customerId, customerName: customerName }, () => {
      var columnName = "customer_id";
      if(customerId && customerName){
        this.tableRef.current.setColumnFilter(`${columnName}:name`, customerName);
      }
      else{
        this.tableRef.current.setColumnFilter(`${columnName}:name`, "");
      }
      this.tableRef.current.api.cells(null, `${columnName}:name`).invalidate().draw();
    });
  }

  selectAccountManager(amId, amName) {
    this.setState({ accountManagerId: amId, accountManagerName: amName }, () => {
      var columnName = "salesman_id";
      if(amId && amName){
        this.tableRef.current.setColumnFilter(`${columnName}:name`, amName);
      }
      else{
        this.tableRef.current.setColumnFilter(`${columnName}:name`, "");
      }
      this.tableRef.current.api.cells(null, `${columnName}:name`).invalidate().draw();
    });
  }

  selectAccountManagerToggle(amName) {
    var columnName = "salesman_id";
    if (amName) {
      this.tableRef.current.setColumnFilter(`${columnName}:name`, amName);
    }
    else {
      this.tableRef.current.setColumnFilter(`${columnName}:name`, "");
    }
    this.tableRef.current.api.cells(null, `${columnName}:name`).invalidate().draw();
  }
  
  handleCreateInvoice(id){
    this.setState({
      busy: true,
      message: null,
      error: null
    });
    
    axios({
      url: "/api/v1/tools/createinvoice",
      method: "get",
      params: {accountingId:id}
    })
      .then(res => {
        this.setState({
          busy: false,
          message: "The operation completed successfully.",
          error: null
        });
        this.tableRef.current.reload(() =>
          this.setState({
            busy: false,
            error: null
          })
        );
      })
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
            ? err.request
            : err.message;
        this.setState({
          busy: false,
          message: null,
          error
        });
      });
  }

  getInvoice(invoiceId) {
    var _this = this;
    this.setState({
      invoiceData: null,
      busy: true,
      message: null,
      error: null
    });

    axios({
      url: "/api/v1/tools/getinvoice",
      method: "get",
      params: {id:invoiceId||null}
    })
      .then(res => {
        var resData = res && res.data ? res.data : null;

        var invoiceLines = (resData.invoice_lines||[]).map((line,idx)=>{
          line.unitPrice = line.unit_price;
          delete line.unit_price;
          return line;
        });
        
        var invoiceData = null;
        
        if(resData){
          invoiceData = {
            id: resData._id || null,
            issuerId: resData.issuer_id||null,
            issuerTitle: resData.issuer_title||null,
            issuerAddress: resData.issuer_address||null,
            issuerAddress2: resData.issuer_address2||null,
            issuerRegNo: resData.issuer_reg_no||null,
            issuerVatNo: resData.issuer_vat_no||null,
            recipientId: resData.recipient_id||null,
            recipientTitle: resData.recipient_title||null,
            recipientAddress: resData.recipient_address||null,
            recipientAddress2: resData.recipient_address2||null,
            recipientRegNo: resData.recipient_reg_no||null,
            recipientVatNo: resData.recipient_vat_no||null,
            accountManagerId: resData.account_manager_id!==null?parseInt(resData.account_manager_id):null,
            accountManagerTitle: resData.account_manager_title||null,
            invoiceNo: resData.invoice_no||null,
            invoiceDate: resData.invoice_date||null,
            invoicePeriod:  resData.invoice_period_from?moment(resData.invoice_period_from).month()+1+"/"+moment(resData.invoice_period_from).year():null,
            //invoicePeriodFrom: resData.invoice_period_from||null,
            invoicePeriodTo: resData.invoice_period_to||null,
            invoiceTypeId: resData.invoice_type_id||null,
            accountCategoryId: resData.account_category_id!==null?parseInt(resData.account_category_id):null,
            accountCode: resData.account_code||null,
            interCompany: resData.inter_company===true?true:false,
            notes: resData.notes||null,
            exchangeRate: resData.exchange_rate?resData.exchange_rate:0,
            taxRate: resData.tax_rate?resData.tax_rate:0,
            unlocked: resData.unlocked===true?true:false,
            exported: resData.exported===true?true:false,
            invoiceLines: invoiceLines||[],
            busy: false,
            message: null,
            error: null
          }
        }
        
        this.setState({invoiceData:invoiceData},
          () => {
            //this.getInvoiceTypes();

            var doc = new jsPDF('p', 'pt', [595, 842]); //2480 x 3508 //595 x 842
            //var doc = new jsPDF('p', 'mm', 'a4'); 

            var pdfjs = document.querySelector('#invoice-html');

            // Convert HTML to PDF in JavaScript
            doc.html(pdfjs, {
              callback: function(doc) {
                doc.save("invoice_no_"+(_this.state?.invoiceData?.invoiceNo||"")+".pdf");
              },
              x: 40,
              y: 40
            });
          });

      })
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
            ? err.request
            : err.message;
        this.setState({
          busy: false,
          message: null,
          error
        });
      });
  }

  constructTableUrl(name, e) {
    //var isInit = name=="init"?true:false;
    var selectedStartDate = (name === "selectedStartDate") ? e : this.state.selectedStartDate ? this.state.selectedStartDate : null;
    var selectedEndDate = (name === "selectedEndDate") ? e : this.state.selectedEndDate ? this.state.selectedEndDate : null;
    var selectedStatus = (name === "selectedStatus") ? e : this.state.selectedStatus;
    var issuer_id = (name === "issuer_id") ? e : (this.state.issuerName ? this.state.issuerName[0] : "");
    var salesman_id = (name === "salesman_id") ? e : (this.state.amName ? this.state.amName[0] : "");
    var customer_id = (name === "customer_id") ? e : (this.state.customerId);
    var qs0 = (selectedStartDate ? "selectedStartDate=" + (selectedStartDate.getFullYear() + '-' + ((selectedStartDate.getMonth() + 1).toString()).padStart(2, "0") + '-01') : "");
    var qs1 = (selectedEndDate ? "selectedEndDate=" + (selectedEndDate.getFullYear() + '-' + ((selectedEndDate.getMonth() + 1).toString()).padStart(2, "0") + '-01') : "");
    var qs2 = (selectedStatus ? "selectedStatus=" + selectedStatus : "");
    var qs3 = (issuer_id ? "issuer_id=" + issuer_id : "");
    var qs4 = (salesman_id ? "salesman_id=" + salesman_id : "");
    var qs5 = (customer_id ? "customer_id=" + customer_id : "");

    var qs = "";
    if (qs0.length || qs1.length || qs2.length || qs3.length || qs4.length || qs5.length) {
      qs = "?" + (qs0.length ? "&" + qs0 : "") + (qs1.length ? "&" + qs1 : "") + (qs2.length ? "&" + qs2 : "") + (qs3.length ? "&" + qs3 : "") + (qs4.length ? "&" + qs4 : "") + (qs5.length ? "&" + qs5 : "");
    }
    var newUrl = this.state.tableUrl + qs;

    return newUrl;
  }

  handleReload(name, e) {
    var newUrl = this.constructTableUrl(name, e);
    this.tableRef.current.setNewUrl(newUrl);
  }

  getTableUrl(init) {
    return this.constructTableUrl(init);
  }
}