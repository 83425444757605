import React from "react";
import { ButtonGroup, Button, FormControl } from "react-bootstrap";
import { Box } from "components/admin-lte";
import AddDialog from "./AddDialog";
import PageLayout from "components/PageLayout";
import { UserContext } from "lib/user";
import 'react-data-grid/lib/styles.css';

import { AgGridReact } from '@ag-grid-community/react'
import 'ag-grid-community/styles//ag-grid.css';
import 'ag-grid-community/styles//ag-theme-quartz.css';

import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';

import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import axios from "axios";

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  MasterDetailModule,
  MenuModule,
  ColumnsToolPanelModule,
  ClipboardModule,
  ExcelExportModule,
  SetFilterModule,
  RangeSelectionModule,
  RowGroupingModule
]);

function renderExpiresDate(params) {
  let d;
  if (params.data && new Date(params.data.expires) > new Date(Date.UTC(2040, 11, 31, 0, 0, 0))) {
    return '';
  }
  if (params.data && params.data.expires) {
    d = params.data.expires.substring(0, params.data.expires.indexOf('T'));
    return d
  }
  else
    return '';
}

function render_blocked_sspdsp(params) {
  if (params.data && params.data.blocked_sspdsp !== null) {
    return params.data.blocked_sspdsp
  }
  else {
    if (params.data && params.data.blocked_dsp === null && params.data.blocked_ssp === null) {
      return 'All/All'
    }
    if (params.data && params.data.blocked_ssp !== null && params.data.blocked_dsp === null) {
      return params.data.blocked_ssp + '/All'
    }
    if (params.data && params.data.blocked_ssp === null && params.data.blocked_dsp !== null) {
      return 'All/' + params.data.blocked_dsp
    }
  }
}

function render_blocked_ssp(params) {
  if (params && params.data) {
    if (params.data && params.data.blocked_ssp !== null && params.data.blocked_dsp === null) {
      return params.data.blocked_ssp
    }
    if (params.data && params.data.blocked_ssp === null && params.data.blocked_dsp !== null) {
      return 'All'
    }
    if (params.data.blocked_ssp === null && params.data.blocked_dsp === null) {
      return 'All'
    }
    if (params.data && params.data.blocked_ssp !== null && params.data.blocked_dsp !== null) {
      return params.data.blocked_ssp
    }
  }
}

function render_blocked_dsp(params) {
  if (params && params.data) {
    if (params.data && params.data.blocked_ssp !== null && params.data.blocked_dsp === null) {
      return 'All'
    }
    if (params.data && params.data.blocked_ssp === null && params.data.blocked_dsp !== null) {
      return params.data.blocked_dsp
    }
    if (params.data.blocked_ssp === null && params.data.blocked_dsp === null) {
      return 'All'
    }
    if (params.data && params.data.blocked_ssp !== null && params.data.blocked_dsp !== null) {
      return params.data.blocked_dsp
    }
  }
}

export default class RTBBlacklists extends React.Component {
  static title = "RTB Blacklists";

  tableRef = React.createRef();
  detailTableRef = React.createRef();
  static customFilter = 'showAll';
  hasSelectedRows = false;

  constructor(props) {
    super(props);

    this.state = {
      columnDefs: [
        {
          headerName: 'Bundle', field: 'bundle',
          filter: 'agSetColumnFilter',
          filterParams: {
            applyMiniFilterWhileTyping: true,
          }
        },
        
        {
          headerName: 'SSP', field: 'blocked_ssp', cellRenderer: render_blocked_ssp,
          filter: 'agSetColumnFilter',
          filterParams: {
            applyMiniFilterWhileTyping: true,
          }
        },
        {
          headerName: 'DSP', field: 'blocked_dsp', cellRenderer: render_blocked_dsp,
          filter: 'agSetColumnFilter',
          filterParams: {
            applyMiniFilterWhileTyping: true,
          }
        },
        /*
        {
          headerName: 'SSP/DSP', field: 'blocked_sspdsp', cellRenderer: render_blocked_sspdsp,
          filter: 'agSetColumnFilter',
          filterParams: {
            applyMiniFilterWhileTyping: true,
          }
        },
        */
        {
          headerName: 'Expires At', field: 'expires', cellRenderer: renderExpiresDate, minWidth: 200,
          cellStyle: params => {
            if (params && params.data && params.data.expires) {
              const now = new Date();
              if (new Date(params.data.expires) > now) {
                return { color: 'black', backgroundColor: '#fffaeb' };
              }
              if (new Date(params.data.expires) <= now) {
                return { color: 'black', backgroundColor: '#fbbc04' };
              }
            }
            return null;
          }
        },
        {
          headerName: 'Date Modified', field: 'moderationDate'
        },
        {
          headerName: 'Filename', field: 'fileName',
          filter: 'agSetColumnFilter',
          filterParams: {
            applyMiniFilterWhileTyping: true,
          }
        },
        {
          headerName: 'Version', field: 'version'
        }
        
      ],
      user: props.user,
      showAdd: false,
      showReply: false,
      showViewAllComments: false,
      commentStatus: null,
      moderationStatus: null,
      rowData: null,
      listData: null,
      rowDetailData: null,
      loading: false,
      selectedIds: [],
      customFilter: 'showAll',
      node: null,
      version: null,
      dataVersion: '',
      listValue: ''
    }
  }

  fetchData(version, showAll, blocked_ssp, blocked_dsp) {
    let API = '/api/v1/tools/rtbblacklists?showALl=true&version=' + (version ? version : '');
    if (!showAll &&blocked_ssp && blocked_dsp) {
      API = '/api/v1/tools/rtbblacklists?version=' + (version ? version : '') + '&blocked_ssp=' + blocked_ssp + '&blocked_dsp=' + blocked_dsp;
    }
    this.setState({ loading: true });
    fetch(API)
      .then((res) => res.json())
      .then((res) => {
        this.setState({ rowData: res, loading: false });
        if (!version) {
          this.setState({ dataVersion: (res.items && res.items.length > 0) ? res.items[0].version : '' });
        }
      })
      .catch(function (error) {
        window.location.replace("/login");
      });

    

    API = '/api/v1/tools/rtbblacklistsdata';
    this.setState({ loading: true });
    fetch(API)
      .then((res) => res.json())
      .then((res) => {
        this.setState({ listData: res, loading: false });
      })
      .catch(function (error) {
        window.location.replace("/login");
      });

    
  }

  rangeHandle(event, suppressWarning) {
    let cellRanges = event.api.getCellRanges();
    let selectedIds = [];
    if (cellRanges && cellRanges.length > 0) {
      cellRanges.forEach((range) => {
        let startRow = Math.min(range.startRow.rowIndex, range.endRow.rowIndex);
        let endRow = Math.max(range.startRow.rowIndex, range.endRow.rowIndex);
        for (let rowIndex = startRow; rowIndex <= endRow; rowIndex++) {
          range.columns.forEach((column) => {
            if (column.colId === '_id') {
              let rowNode = event.api.getDisplayedRowAtIndex(rowIndex);
              let value = event.api.getValue(column, rowNode);
              selectedIds.push(value);
            }
          });
        }
      });
    }
    else {
      selectedIds = []
    }

    if (selectedIds.length > 0) {
      try {
        const API = '/api/v1/tools/updatesuppresswarning';
        const response = fetch(API, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            '_id': selectedIds,
            'suppressWarning': suppressWarning
          })
          //body: JSON.stringify(selectedIds),
        });

        const result = response.json();
        console.log("Success:", result);
      } catch (error) {
        console.error("Error:", error);
      }
    }
  }

  rangeHandleState(event) {
    let cellRanges = event.api.getCellRanges();
    let selectedIds = [];
    if (cellRanges && cellRanges.length > 0) {
      cellRanges.forEach((range) => {
        let startRow = Math.min(range.startRow.rowIndex, range.endRow.rowIndex);
        let endRow = Math.max(range.startRow.rowIndex, range.endRow.rowIndex);
        for (let rowIndex = startRow; rowIndex <= endRow; rowIndex++) {
          range.columns.forEach((column) => {
            if (column.colId === '_id') {
              let rowNode = event.api.getDisplayedRowAtIndex(rowIndex);
              let value = event.api.getValue(column, rowNode);
              selectedIds.push(value);
            }
          });
        }
      });
    }
    else {
      selectedIds = []
    }

    if (document.getElementById("removeWarningBtn") && document.getElementById("enableWarningBtn")) {
      if (selectedIds.length > 0) {
        document.getElementById("removeWarningBtn").classList.remove('disabled')
        document.getElementById("enableWarningBtn").classList.remove('disabled')
      } else {
        document.getElementById("removeWarningBtn").classList.add('disabled')
        document.getElementById("enableWarningBtn").classList.add('disabled')
      }
    }

  }

  isExternalFilterPresent() {
    return true;
  };

  doesExternalFilterPass(node) {
    if (node.data) {
      if (document.getElementById("showAll").checked) {
        return true;
      }
      if (document.getElementById("showExpiringSoon").checked) {
        var nowAfter5Days = new Date();
        nowAfter5Days.setDate(nowAfter5Days.getDate() + 5);
        return (new Date(node.data.expires) <= nowAfter5Days && new Date(node.data.expires)> new Date());
      }
      if (document.getElementById("showExpired").checked) {
        return (new Date(node.data.expires) < new Date());
      }
    }
    return true;
  };

  externalFilterChanged(filter) {
    this.setState({ 'customFilter': filter })
    this.tableRef.current.api.onFilterChanged();
  };

  getRowId(params) {
    return params.data._id
  }

  componentDidMount() {
    this.fetchData()
  }

  onDelete = e => {
    const selectedNodes = this.tableRef.current.api.getSelectedRows();
    this.tableRef.current.api.applyTransaction({ remove: selectedNodes });

    axios({
      url: "/api/v1/tools/deleteRTBBlacklist",
      method: "post",
      data: {
        Items: selectedNodes
      }
    })
      .then(res => {
        this.setState({
          busy: false,
          message: "The operation completed successfully.",
          error: null
        });
        this.props.onHide();
      })
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
            ? err.request
            : err.message;
        this.setState({
          busy: false,
          message: null,
          error
        });
      });

  };

  render() {

    const defaultColDef = {
      flex: 1,
      minWidth: 150,
      filter: 'agTextColumnFilter',
      menuTabs: ['filterMenuTab'],
    };
    const autoSizeStrategy = {
      type: 'fitCellContents'
    };

    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    window.addEventListener('resize', () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });

    return (
      <>
        <PageLayout
          breadcrumbs={["Tools"]}
          title={RTBBlacklists.title}
          description={
            <p></p>
          }
        >
          <UserContext.Consumer>
            {user => (
              <Box>
                <Box.Header>
                  <div style={{ 'display': 'flex' }}>
                    <input
                      type="text"
                      id="filter-text-box"
                      placeholder="Filter..."
                      onChange={(e) => {
                        this.onFilterTextBoxChanged();
                      }}
                      style={{ 'height': '28px' }}
                    />&nbsp;
                    <button type='button' class={'btn btn-default btn-small ' + ((this.state.loading) ? 'disabled' : '')}
                      onClick={() => {
                        this.tableRef.current.api.showLoadingOverlay();
                        if (this.state.version) {
                          this.fetchData(this.state.version);
                        }
                        else {
                          this.fetchData();
                        }
                        
                        //this.tableRef.current.api.setGridOption('rowData', this.state.rowData.items)
                      }}>Refresh</button>&nbsp;&nbsp;&nbsp;
                    <label>
                      <input type='radio' checked={this.state.customFilter==='showAll'} id="showAll" name="filter"
                        onClick={() => {
                          this.externalFilterChanged('showAll');
                        }} />&nbsp;&nbsp;All&nbsp;&nbsp;
                    </label>
                    <label>
                      <input type='radio' id="showExpiringSoon" name="filter"
                        onClick={() => {
                          this.externalFilterChanged('showExpiringSoon');
                        }} />&nbsp;&nbsp;Expiring soon (in5days)&nbsp;&nbsp;
                    </label>
                    <label>
                      <input type='radio' id="showExpired" name="filter"
                        onClick={() => {
                          this.externalFilterChanged('showExpired');
                        }} />&nbsp;&nbsp;Expired&nbsp;&nbsp;
                    </label>                    
                  </div><br/>
                  Filter Blacklists&nbsp;&nbsp;<FormControl style={{ 'width': '220px' }} componentClass="select"
                    placeholder="select"
                    value={this.state.listValue} onChange={e => {
                      let valueSplit = e.target.value.split('|');
                      this.setState({ listValue: e.target.value });
                      this.fetchData(this.state.version, false, valueSplit[0], valueSplit[1]);
                    }
                    }>
                    <option value={''}>{'All'}</option>
                    {this.state.listData?.lists?.map(listItem => (
                      <option value={listItem._id.blocked_ssp + '|' + listItem._id.blocked_dsp}>{'SSP: ' + (listItem._id.blocked_ssp ? listItem._id.blocked_ssp : 'All') + ' - DSP: ' + (listItem._id.blocked_dsp ? listItem._id.blocked_dsp : 'All')}</option>
                    ))}
                  </FormControl>&nbsp;&nbsp;
                  <div style={{ 'display': 'flex', 'width': '600px', 'height': '3.5rem', 'line-height': '3.5rem'}}>
                    <Button
                      disabled={this.state.loading ? true : false}
                      bsStyle="primary"
                      onClick={this.handleAddClick.bind(this, user)}>
                      <i className="fa fa-plus"></i> Upload Blacklist
                    </Button>&nbsp;&nbsp;
                    Blacklist Versions&nbsp;&nbsp;<FormControl style={{ 'width': '220px' }} componentClass="select"
                      placeholder="select"
                      value={ this.state.version } onChange={e => {
                        this.setState({ version: e.target.value, listValue: '' });
                        this.fetchData(e.target.value);
                    }
                    }>
                      <option value={''}>{this.state.dataVersion ? 'V' + this.state.dataVersion +' - Current': '-' }</option>
                      {this.state.listData?.versionItems?.map(versionItem => (
                        <option value={versionItem.version}>{'V' + versionItem.version + ' - ' + versionItem.moderationDate + ' (' + versionItem.versionChanges + (versionItem.versionChanges===1?' change':' changes') + ', Remove Existing:' + (versionItem.removeExisting?'yes':'no') + ') '}</option>
                      ))}                      
                    </FormControl>&nbsp;&nbsp;
                    <Button
                      bsStyle="primary"
                      disabled={!this.state.version || this.state.loading?true:false }
                      onClick={e => {
                        this.setState({ loading: true });
                        this.tableRef.current.api.showLoadingOverlay();
                        this.handleRevertClick(this.state.version, user);
                        this.setState({ loading: false });
                        this.setState({ version: '' });
                      }}>
                      <i className="fa fa-undo"></i> {this.state.version ? 'Revert to V' + this.state.version : 'Revert'}
                    </Button>&nbsp;&nbsp;
                  </div>
                  <br />
                  <div><button disabled={this.state.loading ? true : false}onClick={this.onDelete}>Delete Selected</button><br /></div>
                </Box.Header>
                <Box.Body>
                  <div
                    className="ag-theme-quartz"
                    style={{
                      width: '100%'
                    }}
                  >
                    <AgGridReact
                      onGridReady={params => (this.gridApi = params.api)}
                      id="masterGrid"
                      columnMenu={'new'}
                      autoSizeStrategy={autoSizeStrategy}
                      ref={this.tableRef}
                      columnDefs={this.state.columnDefs}
                      rowData={(this.state.rowData && this.state.rowData.items && this.state.rowData.items.length > 0) ?
                        this.state.rowData.items : []}
                      defaultColDef={defaultColDef}
                      enableRangeSelection={true}
                      enableRangeHandle={true}
                      style={{ width: '100%', height: '70vh' }}
                      overlayLoadingTemplate={'<div class="overlay"><i class="fa fa-refresh fa-spin"></i></div>'}
                      overlayNoRowsTemplate={'<div class="overlay"><i class="fa fa-refresh fa-spin"></i></div>'}
                      onRangeSelectionChanged={this.rangeHandleState}
                      rowSelection={"multiple"}
                      isExternalFilterPresent={this.isExternalFilterPresent}
                      doesExternalFilterPass={this.doesExternalFilterPass}
                      getRowId={this.getRowId}
                      groupDisplayType={'groupRows'}
                    >
                    </AgGridReact>
                  </div>
                </Box.Body>
              </Box>
            )}
          </UserContext.Consumer>          

        </PageLayout>
        {this.state.showAdd && (
          <AddDialog
            listData={this.state.listData}
            onHide={() => {
              this.tableRef.current.api.showLoadingOverlay();
              this.fetchData();
              this.setState({
                busy: false,
                error: null,
                showAdd: false
              })
            }}
          />
        )}
      </>
    );
  }

  onFilterTextBoxChanged() {
    this.tableRef.current.api.setGridOption(
      'quickFilterText',
      document.getElementById('filter-text-box').value
    );
  }

  handleAddClick(user, e) {
    if (user.tokenHasExpired) {
      e.preventDefault();
      window.location.replace('/login');
    } else {
      this.setState({ showAdd: true });
    }
  }

  handleRevertClick(version, user, e) {
    if (user.tokenHasExpired) {
      //e.preventDefault();
     window.location.replace('/login');
    } else {
      let API = '/api/v1/tools/revertrtbblacklist?version=' + (version ? version : '');
      //this.setState({ loading: true });
      fetch(API)
        .then((res) => res.json())
        .then((res) => {
          //this.setState({ loading: false });
          this.fetchData();
        })
        .catch(function (error) {
          window.location.replace("/login");
        });
    }
  }

}