export function initTrackingProvidersList() {
  const trackingProviders= [
   {
      "id": "56001d06000007",
      "name": "Appsflyer",
      "description": "Appsflyer"
    },
    {
      "id": "56001d06000002",
      "name": "Adjust",
      "description": "Adjust"
    },
    {
      "id": "56001d06000008",
      "name": "Branch",
      "description": "Branch"
    },
    {
      "id": "56001d06000009",
      "name": "Trafficguard",
      "description": "Trafficguard"
    },
    {
      "id": "56001d06000010",
      "name": "AppMetrica",
      "description": "AppMetrica"
    },
    /*
    {
      "id": "56001d06000001",
      "name": "MAT",
      "description": "Mat"
    },
    {
      "id": "56001d86000006",
      "name": "Other",
      "description": "Other"
    }*/
  ];

  return trackingProviders;
}